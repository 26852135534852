import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    styles: {
        global: (props) => ({
            body: {
                bgGradient: props.colorMode === 'light'
                    ? 'linear(to-r, blue.50, purple.50)'
                    : 'linear(to-r, blue.800, purple.800)',
                color: props.colorMode === 'light' ? 'gray.800' : 'whiteAlpha.900'
            }
        })
    },
    components: {
        Heading: {
            baseStyle: {
                color: 'inherit',
            },
            variants: {
                gradient: (props) => ({
                    bgGradient: props.colorMode === 'light'
                        ? 'linear(to-l, #FF0080, #7928CA)'
                        : 'linear(to-r, orange.100, purple.200)',
                    bgClip: 'text',
                    color: 'transparent',
                }),
            },
        },
        NavBar: {
            baseStyle: (props) => ({
                bgGradient: props.colorMode === 'light'
                    ? 'linear(to-r, blue.100, purple.100)'
                    : 'linear(to-r, blue.900, purple.900)',
            }),
        },
        Footer: {
            baseStyle: (props) => ({
                bgGradient: props.colorMode === 'light'
                    ? 'linear(to-r, gray.50, gray.50)'
                    : 'linear(to-r, gray.900, gray.900)',
            }),
        },
    },
})

export default theme