import React from "react";

import {Heading, Text} from '@chakra-ui/react'
import { Container, Flex } from '@chakra-ui/react'

import Nav from "../components/Nav";


export default function PageNotFound() {
    return (
        <div>
            <Nav />

            <Flex as="main" role="main" direction="column" flex="1" py="4">
                <Container  maxW='container.xl'>

                    <Heading variant="gradient" as='h1'>404 - Page Not Found</Heading>
                    <Text>Sorry, the page you are looking for could not be found.</Text>

                </Container>
            </Flex>
        </div>
    );
}
