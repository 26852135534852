// Run with 'npm start'

import React, { useCallback, useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from "../components/Nav";
// import StatusIndicatorActive from "../components/StatusIndicatorActive";
// import StatusIndicatorInactive from "../components/StatusIndicatorInactive";
import BasicStatistics from "../components/BasicStatistics";
import Footer from "../components/Footer";
import {Stat} from "../components/Stat";
// import {ChakraReactTable} from "../components/ChakraReactTable";
import Login from "../components/Login";

// import { ChakraProvider } from '@chakra-ui/react'
import { Container, Flex, SimpleGrid } from '@chakra-ui/react'
import { Heading, Button, Card, CardBody, Box, Text, Stack } from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { Badge } from '@chakra-ui/react'
import {
    ListItem,
    UnorderedList,
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'

import useWebSocket, { ReadyState } from 'react-use-websocket';

import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import ZoomPlugin from 'chartjs-plugin-zoom';
import StreamingPlugin from 'chartjs-plugin-streaming';
import Chart from 'chart.js/auto';

// import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

////// Binance WebSocket info stream
// const WS_URL = 'wss://ws.kumarannathan.com/'; // This has DNS CNAME for fly.io host (old-thunder-127.fly.dev)
// // const WS_URL = 'wss://old-thunder-127.fly.dev/'; // fly.io host (old-thunder-127.fly.dev)
// // const WS_URL = 'ws://209tpr.duckdns.org:18001/'; // Home router redirects 18001 public to correct port on laptop
// // const WS_URL = 'ws://localhost:8323/';

////// KuCoin WebSocket info stream
// const WS_URL2 = 'wss://wskucoin.kumarannathan.com/'; // fly.io host (dawn-wave-6563.fly.dev) or Oracle Cloud Docker host
// const WS_URL2 = 'wss://dawn-wave-6563.fly.dev/'; // fly.io host (dawn-wave-6563.fly.dev)
// const WS_URL2 = 'ws://209tpr.duckdns.org:18001/'; // Home router redirects 18001 public to correct port on laptop
// const WS_URL2 = 'ws://localhost:8323/';
// const WS_URL2 = 'ws://141.147.75.108:8323/';  // Oracle Cloud host routes requests to 443 to 8323 in the docker container
const WS_URL2 = 'wss://wskucoin.kumarannathan.com/';  // Oracle Cloud host routes requests to 443 to 8323 in the docker container


Chart.register(ZoomPlugin, StreamingPlugin);

const data = {datasets: []}

const options= {
    scales: {
        x: {
            type: 'realtime'
        },
        y: {
            title: {
                display: true,
                text: 'Value'
            }
        }
    },
    interaction: {
        intersect: false
    },
    plugins: {
        zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                pinch: {
                    enabled: true
                },
                wheel: {
                    enabled: true
                },
                mode: 'x'
            },
            limits: {
                x: {
                    minDelay: 0,
                    maxDelay: 0,
                    minDuration: 1000,
                    maxDuration: 20000
                }
            }
        }
    }
}

const data_funding_rates = {datasets: []}

const stats = [
    {
        label: 'GBP-equivalent',
        value: '£x,xxxx.xx',
    },
    {
        label: 'USDT-equivalent',
        value: 'x,xxxx.xx',
    },
    {
        label: 'Some other stat',
        value: 'xx.xx%',
    },
]

function updateBidAskChart(chart, exchange, symbol, ask, bid) {
    let full_label_ask = "Ask price (" + symbol + " @ " + exchange + ")";
    let full_label_bid = "Bid price (" + symbol + " @ " + exchange + ")";

    let index_ask = chart.data.datasets.findIndex(x => x.label===full_label_ask);
    let index_bid = chart.data.datasets.findIndex(x => x.label===full_label_bid);

    if (index_ask === -1) {
        chart.data.datasets.push({
            label: full_label_ask,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: []});
        chart.update('none');
        index_ask = chart.data.datasets.findIndex(x => x.label===full_label_ask);
    }

    if (index_bid === -1) {
        chart.data.datasets.push({
            label: full_label_bid,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            data: []});
        chart.update('none');
        index_bid = chart.data.datasets.findIndex(x => x.label===full_label_bid);
    }

    chart.data.datasets[index_ask].data.push({x: Date.now(), y: ask});
    chart.data.datasets[index_bid].data.push({x: Date.now(), y: bid});
}

function updateFundingRateChart(chart, exchange, symbol, fundingRate) {
    let full_label = "Funding rate (" + symbol + " @ " + exchange + ")";
    let index = chart.data.datasets.findIndex(x => x.label===full_label);

    if (index === -1) {
        chart.data.datasets.push({
            label: full_label,
            backgroundColor: 'rgb(200, 70, 182)',
            borderColor: 'rgb(200, 70, 182)',
            data: []});
        chart.update('none');
        index = chart.data.datasets.findIndex(x => x.label===full_label);
    }

    chart.data.datasets[index].data.push({x: Date.now(), y: fundingRate});
}

function Trade() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    let chart1 = Chart.getChart('chart1'); // Select by canvas ID
    let chart2 = Chart.getChart('chart2'); // Select by canvas ID

    // const { sendMessage: sendMessageBinance,
    //     readyState: readyStateBinance } = useWebSocket(WS_URL, {
    //     onOpen: () => {
    //         console.log('WebSocket connection opened to ' + WS_URL + ' (Binance).');
    //
    //         },
    //     onClose: () => {
    //         console.log('WebSocket connection closed to ' + WS_URL + ' (Binance).');
    //         },
    //     shouldReconnect: () => true,
    //     onMessage: (event: WebSocketEventMap['message']) => {
    //         const response = JSON.parse(event.data);
    //         // console.log(response)
    //         if (response.channel === 'updateBidAskPrices') {
    //             updateBidAskChart(chart1, response.exchange, response.symbol, response.ask, response.bid)
    //         } else if (response.channel === 'updateFundingRate') {
    //             let exchange = 'Binance_futures'
    //             updateFundingRateChart(chart2, exchange, response.symbol, response.fundingRate)
    //         }
    //     }
    // });

    const { sendMessage: sendMessageKuCoin,
        readyState: readyStateKucoin } = useWebSocket(WS_URL2, {
        // const { sendMessage: sendMessageKuCoin } = useWebSocket(WS_URL2, {
        onOpen: () => {
            console.log('WebSocket connection opened to ' + WS_URL2 + ' (KuCoin).');
        },
        onClose: () => {
            console.log('WebSocket connection closed to ' + WS_URL2 + ' (KuCoin).');
        },
        shouldReconnect: () => true,

        onMessage: (event: WebSocketEventMap['message']) => {
            const response = JSON.parse(event.data);
            // console.log(response)

            if (response.channel === 'updateKuCoinContracts') {
                updateKuCoinContractTable(response)
            }
            else if (response.channel === 'updateAccountOverview') {
                setAccountEquity(response.accountEquity);
                setUnrealisedPNL(response.unrealisedPNL)
                setMarginBalance(response.marginBalance)
                setPositionMargin(response.positionMargin)
                setOrderMargin(response.orderMargin)
                setFrozenFunds(response.frozenFunds)
                setAvailableBalance(response.availableBalance)
                setAccountLastUpdate(new Date(response.timestamp).toLocaleString())
                updateKuCoinHoldingsTable(response, 'getapi')
                // console.log(response)
            }
            else if (response.channel === 'updateKuCoinHoldings') {
                updateKuCoinHoldingsTable(response, 'websocket')
            }
            else if (response.channel === 'updatePosition') {
                updateKuCoinPositionsTable(response)
                // console.log(response)
            }
            else if (response.channel === 'updateOpenOrder') {
                updateKuCoinOpenOrderTable(response)
                // console.log(response)
            }
            else if (response.channel === 'updateFundingRateTradeDetails') {
                updateKuCoinFundingRateTradeDetailsTable(response)
                // console.log(response)
            }
            else if (response.channel === 'numConnectedClients') {
                setNumConnectedClients(response.numConnectedClients)
                setLastUpdatedNumClients(new Date(response.timestamp).toLocaleString())
                // console.log(response)
            }
            else {
                console.log(response)
            }
        }
    });

    // const connectionStatusBinance = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyStateBinance];

    const connectionStatusKucoin = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyStateKucoin];

    const gridRef1 = useRef();
    const gridRef3 = useRef();
    const gridRef4 = useRef();
    const gridRef5 = useRef();

    const gridOptions1 = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        columnDefs: [
            { field: "symbol" },
            { field: "fundingFeeRate", headerName: 'Funding fee rate (FFR)', cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "Abs_FFR", sort: 'desc', cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "predictedFundingFeeRate", headerName: 'Predicted FFR', cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "Abs_PFFR", cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "nextFundingRateTime", cellRenderer: props => { return `${new Date(props.value).toLocaleString()}`; }},
            { field: "markPrice" },
            { field: "indexPrice" },
            { field: "lastTradePrice" },
            { field: "midPrice" },
            { field: "bestBidPrice" },
            { field: "bestAskPrice" },
        ],
        pagination: true,
        // paginationAutoPageSize: true,
        paginationPageSize: 10,
        paginationPageSizeSelector: [5, 10, 20],
    };

    const gridOptions3 = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        columnDefs: [
            { field: "symbol" },
            { field: "unrealisedPNL", sort: 'desc' },
            { field: "positionMargin" },
            { field: "markPrice" },
            { field: "lastTradePrice" },
            { field: "avgEntryPrice" },
            { field: "liquidationPrice" },
            { field: "stopPrice" },
            { field: "qty" },
            { field: "initialPosition" },
            { field: "posComm" },
            { field: "realisedPNL" },
            { field: "initialAndRealised" },
            { field: "posCost" },
            { field: "indexPrice" },
            { field: "fundingFeeRate", cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "predictedFundingFeeRate", cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
        ],
    };

    const gridOptions4 = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        columnDefs: [
            { field: "symbol" },
            { field: "profit", headerName: 'Profit (BBO)', sort: 'desc', cellRenderer: props => { return `${Math.round(props.value * 1000000) / 1000000}`; }},
            { field: "profitl2", headerName: 'Profit (L2)', cellRenderer: props => { return `${Math.round(props.value * 1000000) / 1000000}`; }},
            { field: "entryTotalL2", headerName: 'Total cost (L2)', cellRenderer: props => { return `${Math.round(props.value * 1000000) / 1000000}`; }},
            { field: "RoI", headerName: 'RoI % (BBO)', cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "bidAskSpreadPercent", headerName: 'Bid-Ask spread (%)', cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "fundingFeeRate", cellRenderer: props => { return `${Math.round(props.value * 100 * 10000) / 10000}%`; }},
            { field: "bestBidQty" },
            { field: "bestAskQty" },
            // { field: "secondsToFunding" },
        ],
        pagination: true,
        // paginationAutoPageSize: true,
        paginationPageSize: 9,
        paginationPageSizeSelector: [5, 10, 20],
    };

    const gridOptions5 = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMovable: true,
        },
        columnDefs: [
            { field: "orderId", headerName: 'orderId', sort: 'desc' },
            { field: "symbol" },
            { field: "orderType", headerName: 'orderType' },
            { field: "side" },
            { field: "size" },
            { field: "price" },
            { field: "stopPrice", headerName: 'stopPrice' },
            { field: "filledQty", headerName: 'filledQty' },
            { field: "remainQty", headerName: 'remainQty' },
            // { field: "secondsToFunding" },
        ],
    };

    const [rowData1] = useState([]);
    const [rowData3] = useState([]);
    const [rowData4] = useState([]);
    const [rowData5] = useState([]);

    const getRowId1 = params => params.data.symbol;  // callback tells the grid to use the 'symbol' attribute
    const getRowId3 = params => params.data.symbol;  // callback tells the grid to use the 'symbol' attribute
    const getRowId4 = params => params.data.symbol;  // callback tells the grid to use the 'symbol' attribute
    const getRowId5 = params => params.data.orderId;  // callback tells the grid to use the 'orderId' attribute

    const gridRef2 = useRef();

    function errorValueGetter(params) {
        return Math.round((params.data.getapi - params.data.websocket) * 10000000000)/10000000000
    }

    const gridOptions2 = {
        defaultColDef: {
            sortable: false,
            resizable: true,
            suppressMovable: true,
        },
        columnDefs: [
            { field: "description", headerName: 'Description' },
            { field: "getapi", headerName: 'GET API' },
            { field: "websocket", headerName: 'WebSocket' },
            { field: "error", headerName: 'Error', valueGetter: errorValueGetter },
        ],
    };

    const getRowId2 = params => params.data.description;  // callback tells the grid to use the 'symbol' attribute

    const [lastUpdated, setLastUpdated] = useState("No updates yet");

    const [numConnectedClients, setNumConnectedClients] = useState(0);
    const [lastUpdatedNumClients, setLastUpdatedNumClients] = useState("N/A");

    const [accountEquity, setAccountEquity] = useState(0.0);
    const [unrealisedPNL, setUnrealisedPNL] = useState(0.0);
    const [marginBalance, setMarginBalance] = useState(0.0);
    const [positionMargin, setPositionMargin] = useState(0.0);
    const [orderMargin, setOrderMargin] = useState(0.0);
    const [frozenFunds, setFrozenFunds] = useState(0.0);
    const [availableBalance, setAvailableBalance] = useState(0.0);
    const [accountLastUpdate, setAccountLastUpdate] = useState("No updates yet");

    function updateKuCoinContractTable(response) {
        var data = response.data;

        for (var i = 0; i < data.length; i++) {
            updateKuCoinContractRow(data[i]);
        }

        const allColumnIds = [];
        gridRef1.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef1.current.columnApi.autoSizeColumns(allColumnIds, false);

        gridRef1.current.api.refreshClientSideRowModel()

        setLastUpdated(new Date(response.timestamp).toLocaleString());
    }

    function updateKuCoinContractRow(response) {
        const newData = {
            symbol: response.symbol,
            fundingFeeRate: response.fundingFeeRate,
            Abs_FFR: Math.abs(response.fundingFeeRate),
            predictedFundingFeeRate: response.predictedFundingFeeRate,
            Abs_PFFR: Math.abs(response.predictedFundingFeeRate),
            nextFundingRateTime: response.nextFundingRateTime,
            markPrice: response.markPrice,
            indexPrice: response.indexPrice,
            lastTradePrice: response.lastTradePrice,
            midPrice: response.midPrice,
            bestBidPrice: response.bestBidPrice,
            bestAskPrice: response.bestAskPrice,
        };

        const rowNode = gridRef1.current.api.getRowNode(response.symbol);

        if (rowNode) {
            rowNode.updateData(newData);
        } else {
            gridRef1.current.api.applyTransaction({ add: [newData] })
        }

        // console.log('Updated ' + symbol + ' in KuCoin Contract Table.');
    }

    function updateKuCoinPositionsTable(response) {
        var data = response.data;

        for (var i = 0; i < data.length; i++) {
            updateKuCoinPositionRow(data[i]);
        }

        const allColumnIds = [];
        gridRef3.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef3.current.columnApi.autoSizeColumns(allColumnIds, false);

        gridRef3.current.api.refreshClientSideRowModel()

        setLastUpdated(new Date(response.timestamp).toLocaleString());
    }

    function updateKuCoinPositionRow(response) {
        const newData = {
            symbol: response.symbol,
            qty: response.qty,
            initialPosition: response.initialPosition,
            posComm: response.posComm,
            realisedPNL: response.realisedPNL,
            initialAndRealised: response.initialAndRealised,
            unrealisedPNL: response.unrealisedPNL,
            positionMargin: response.positionMargin,
            posCost: response.posCost,
            markPrice: response.markPrice,
            indexPrice: response.indexPrice,
            lastTradePrice: response.lastTradePrice,
            avgEntryPrice: response.avgEntryPrice,
            liquidationPrice: response.liquidationPrice,
            stopPrice: response.stopPrice,
            fundingFeeRate: response.fundingFeeRate,
            predictedFundingFeeRate: response.predictedFundingFeeRate,
        };

        const rowNode = gridRef3.current.api.getRowNode(response.symbol);

        if (rowNode) {
            if (response.qty !== 0) {
                rowNode.updateData(newData);
            }
            else {  // Remove row if qty == 0
                gridRef3.current.api.applyTransaction({ remove: [rowNode.data] });
            }
        } else {
            gridRef3.current.api.applyTransaction({ add: [newData] })
        }

        // console.log('Updated ' + symbol + ' in KuCoin Contract Table.');
    }


    function updateKuCoinOpenOrderTable(response) {
        var data = response.data;

        for (var i = 0; i < data.length; i++) {
            updateKuCoinOpenOrderRow(data[i]);
        }

        const allColumnIds = [];
        gridRef5.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef5.current.columnApi.autoSizeColumns(allColumnIds, false);

        gridRef5.current.api.refreshClientSideRowModel()

        setLastUpdated(new Date(response.timestamp).toLocaleString());
    }

    function updateKuCoinOpenOrderRow(response) {
        const newData = {
            orderId: response.orderId,
            symbol: response.symbol,
            orderType: response.orderType,
            side: response.side,
            price: response.price,
            stopPrice: response.stopPrice,
            size: response.size,
            filledQty: response.filledQty,
            remainQty: response.remainQty,
        };

        const rowNode = gridRef5.current.api.getRowNode(response.orderId);

        if (rowNode) {
            if (response.remainQty > 0) {
                rowNode.updateData(newData);
            }
            else {  // Remove row if remainQty == 0
                gridRef5.current.api.applyTransaction({ remove: [rowNode.data] });
            }
        } else {
            if (response.remainQty > 0) {
                gridRef5.current.api.applyTransaction({ add: [newData] })
            }
        }
    }

    function updateKuCoinFundingRateTradeDetailsTable(response) {
        var data = response.data;

        for (var i = 0; i < data.length; i++) {
            updateKuCoinFundingRateTradeDetailRow(data[i]);
        }

        const allColumnIds = [];
        gridRef4.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef4.current.columnApi.autoSizeColumns(allColumnIds, false);

        gridRef4.current.api.refreshClientSideRowModel()

        setLastUpdated(new Date(response.timestamp).toLocaleString());
    }

    function updateKuCoinFundingRateTradeDetailRow(response) {
        const newData = {
            symbol: response.symbol,
            profit: response.profit,
            profitl2: response.profitl2,
            entryTotalL2: response.entryTotalL2,
            RoI: response.RoI,
            cost: response.cost,
            proceeds: response.proceeds,
            fundingFee: response.fundingFee,
            bidAskSpread: response.bidAskSpread,
            bidAskSpreadPercent: response.bidAskSpreadPercent,
            fundingFeeRate: response.fundingFeeRate,
            markPrice: response.markPrice,
            bestBidQty: response.bestBidQty,
            bestAskQty: response.bestAskQty,
            leverage: response.leverage,
            // secondsToFunding: response.secondsToFunding,
            indexPrice: response.indexPrice,
        };

        const rowNode = gridRef4.current.api.getRowNode(response.symbol);

        if (rowNode) {
            rowNode.updateData(newData);
        } else {
            gridRef4.current.api.applyTransaction({ add: [newData] })
        }

        // console.log('Updated ' + symbol + ' in KuCoin Contract Table.');
    }



    const accountDetailsRows = [
        'accountEquity',
        'unrealisedPNL',
        'positionMargin',
        'availableBalance',
        'marginBalance',
        'orderMargin',
        'frozenFunds',
    ];

    // function initialiseKuCoinHoldingsTable() {
    //     for (let i = 0; i < accountDetailsRows.length; i++) {
    //         const rowNode = gridRef2.current.api.getRowNode(accountDetailsRows[i]);
    //         if (!rowNode) {
    //             gridRef2.current.api.applyTransaction({ add: [{ description: accountDetailsRows[i] }] })
    //         }
    //     }
    // }

    function updateKuCoinHoldingsTable(response, source) {
        // initialiseKuCoinHoldingsTable()

        setLastUpdated(new Date(response.timestamp).toLocaleString());

        for (var key in response) {
            if (accountDetailsRows.includes(key)) {
                const rowNode = gridRef2.current.api.getRowNode(key);
                rowNode.setDataValue(source, response[key]);
            }
        }

        const allColumnIds = [];
        gridRef2.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef2.current.columnApi.autoSizeColumns(allColumnIds, false);

        gridRef2.current.api.refreshClientSideRowModel()
    }

    // const handleClickRestart = useCallback(() => sendMessageBinance('RESTART'), []);

    const handleClickKuCoin = useCallback(() => sendMessageKuCoin('RESTART'), []);

    // function chartOne() {
    //     return (
    //         <Stack py={2} spacing={2}>
    //            {binancewsConnected()}
    //            <Line data={data} options={options} id='chart1'/>
    //        </Stack>
    //     )
    // }

    // function chartTwo() {
    //     return (
    //         <Stack py={2} spacing={2}>
    //             {binancewsConnected()}
    //             <Line data={data_funding_rates} options={options} id='chart2'/>
    //         </Stack>
    //     )
    // }

    // function binancewsConnected() {
    //     if (connectionStatusBinance === 'Open') {
    //         return(
    //             <Stack
    //                 direction={{ /*base: 'column',*/ md: 'row' }}
    //                 justify={{ base: 'left' }}
    //                 align={{ base: 'center', md: 'center' }}
    //             >
    //                 <Text fontSize='sm'>WebSocket:</Text>
    //                 <Badge colorScheme='green'>{connectionStatusBinance}</Badge>
    //                 {/*<StatusIndicatorActive />*/}
    //                 <Button
    //                     onClick={handleClickRestart}
    //                     colorScheme='gray'
    //                     size='xs'
    //                     isDisabled={readyStateBinance !== ReadyState.OPEN}
    //                     leftIcon={<RepeatIcon />}
    //                 >
    //                     Reset server
    //                 </Button>
    //             </Stack>
    //         );
    //     } else {
    //         return (
    //             <Stack
    //                 direction={{ /*base: 'column',*/ md: 'row' }}
    //                 justify={{ base: 'left' }}
    //                 align={{ base: 'center', md: 'center' }}
    //             >
    //                 <Text fontSize='sm'>WebSocket:</Text>
    //                 <Badge colorScheme='red'>{connectionStatusBinance}</Badge>
    //                 {/*<StatusIndicatorInactive />*/}
    //                 {/*<Text fontSize='sm'>*/}
    //                 {/*    {connectionStatusBinance}*/}
    //                 {/*</Text>*/}
    //             </Stack>
    //         );
    //     }
    // }

    function kcwsConnected() {
        if (connectionStatusKucoin === 'Open') {
            return(
                <Stack
                    direction={{ /*base: 'column',*/ md: 'row' }}
                    justify={{ base: 'left' }}
                    align={{ base: 'center', md: 'center' }}
                >
                    <Text fontSize='sm'>WebSocket:</Text>
                    <Badge colorScheme='green'>{connectionStatusKucoin}</Badge>
                    {/*<StatusIndicatorActive />*/}
                    <Button
                        onClick={handleClickKuCoin}
                        colorScheme='gray'
                        size='xs'
                        isDisabled={readyStateKucoin !== ReadyState.OPEN}
                        leftIcon={<RepeatIcon />}
                    >
                        Reset server
                    </Button>
                </Stack>
            );
        } else {
            return (
                <Stack
                    direction={{ /*base: 'column',*/ md: 'row' }}
                    justify={{ base: 'left' }}
                    align={{ base: 'center', md: 'center' }}
                >
                    <Text fontSize='sm'>WebSocket:</Text>
                    <Badge colorScheme='red'>{connectionStatusKucoin}</Badge>
                    {/*<StatusIndicatorInactive />*/}
                    {/*<Text fontSize='sm'>*/}
                    {/*    {connectionStatusKucoin}*/}
                    {/*</Text>*/}
                </Stack>
            );
        }
    }

    function kucoinSymbolDetails() {
        return (
            <Stack py={2} spacing={2}>
                {kcwsConnected()}
                <Text fontSize='sm'>Last update: {lastUpdated}</Text>
                <div className="ag-theme-alpine" style={{ height: 500 }}>
                    <AgGridReact
                        ref={gridRef1}
                        rowData={rowData1}
                        gridOptions={gridOptions1}
                        animateRows={true}
                        getRowId={getRowId1}
                        enableCellChangeFlash={true}
                    />
                </div>
            </Stack>
        )
    }

    function KucoinHoldings() {
        const [rowData2, setRowData2] = useState([]);

        // This effect will be invoked the first time the component renders
        React.useEffect(() => {
            (async () => {
                var data = [];
                for (let i = 0; i < accountDetailsRows.length; i++) {
                    data.push({description: accountDetailsRows[i]})
                }
                setRowData2(data);
            })();
        }, []);

        return (
            <Stack py={2} spacing={2}>
                {kcwsConnected()}
                <Text fontSize='sm'>Last update: {lastUpdated}</Text>
                <div className="ag-theme-alpine" style={{ height: 350 }}>
                    <AgGridReact
                        ref={gridRef2}
                        rowData={rowData2}
                        gridOptions={gridOptions2}
                        animateRows={true}
                        getRowId={getRowId2}
                        enableCellChangeFlash={true}
                    />
                </div>
            </Stack>
        )
    }

    function kucoinPositions() {
        return (
            <Stack py={2} spacing={2}>
                {kcwsConnected()}
                <Text fontSize='sm'>Last update: {lastUpdated}</Text>
                <div className="ag-theme-alpine" style={{ height: 250 }}>
                    <AgGridReact
                        ref={gridRef3}
                        rowData={rowData3}
                        gridOptions={gridOptions3}
                        animateRows={true}
                        getRowId={getRowId3}
                        enableCellChangeFlash={true}
                    />
                </div>
            </Stack>
        )
    }

    function kucoinOpenOrders() {
        return (
            <Stack py={2} spacing={2}>
                {kcwsConnected()}
                <Text fontSize='sm'>Last update: {lastUpdated}</Text>
                <div className="ag-theme-alpine" style={{ height: 250 }}>
                    <AgGridReact
                        ref={gridRef5}
                        rowData={rowData5}
                        gridOptions={gridOptions5}
                        animateRows={true}
                        getRowId={getRowId5}
                        enableCellChangeFlash={true}
                    />
                </div>
            </Stack>
        )
    }

    function kucoinFundingRateTradeDetails() {
        return (
            <Stack py={2} spacing={2}>
                {kcwsConnected()}
                <Text fontSize='sm'>Last update: {lastUpdated}</Text>
                <div className="ag-theme-alpine" style={{ height: 500 }}>
                    <AgGridReact
                        ref={gridRef4}
                        rowData={rowData4}
                        gridOptions={gridOptions4}
                        animateRows={true}
                        getRowId={getRowId4}
                        enableCellChangeFlash={true}
                    />
                </div>
            </Stack>
        )
    }

    function statsBoxes() {
        return (
            <Box
                as="section"
                py={{
                    base: '4',
                    md: '8',
                }}
            >
                <Container>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 3,
                        }}
                        gap={{
                            base: '5',
                            md: '6',
                        }}
                    >
                        {stats.map(({ label, value }) => (
                            <Stat key={label} label={label} value={value} />
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
        )
    }

    return (
        <div>
            <Nav />

            <Flex as="main" role="main" direction="column" flex="1" py="4">
                <Container  maxW='container.xl'>
                    <Stack py={2} spacing={4}>

                        <Box>
                            <Heading variant="gradient" as='h1'>Trading dashboard</Heading>
                        </Box>

                        {/*<BasicStatistics />*/}

                        {/*<Card size={'sm'}>*/}
                        {/*    <CardBody>*/}
                        {/*        <Accordion allowToggle defaultIndex={0}>*/}
                        {/*            <AccordionItem>*/}
                        {/*                <AccordionButton>*/}
                        {/*                    <Box as="span" flex='1' textAlign='left'>*/}
                        {/*                        <Heading as='h2'  size={'lg'}>Example (Chakra UI + React Table)</Heading>*/}
                        {/*                    </Box>*/}
                        {/*                    <AccordionIcon />*/}
                        {/*                </AccordionButton>*/}
                        {/*                <AccordionPanel>*/}
                        {/*                    <ChakraReactTable />*/}
                        {/*                </AccordionPanel>*/}
                        {/*            </AccordionItem>*/}
                        {/*        </Accordion>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}

                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2' size={'lg'}>Account overview (USDT)</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            <Stack py={2} spacing={2}>
                                                {kcwsConnected()}
                                                <Text fontSize='sm'>Last update: {accountLastUpdate}</Text>
                                                {/*<BasicStatistics />*/}
                                                <BasicStatistics accountOverview={{
                                                    accountEquity: accountEquity,
                                                    unrealisedPNL: unrealisedPNL,
                                                    marginBalance: marginBalance,
                                                    positionMargin: positionMargin,
                                                    orderMargin: orderMargin,
                                                    frozenFunds: frozenFunds,
                                                    availableBalance: availableBalance }} />

                                                {/*{statsBoxes()}*/}

                                                <Accordion allowToggle>
                                                    <AccordionItem>
                                                        <AccordionButton>
                                                            <Box as="span" flex='1' textAlign='left'>
                                                                <Heading as='h3'  size={'md'}>Definitions</Heading>
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                        <AccordionPanel>
                                                            <UnorderedList>
                                                                <ListItem><b>accountEquity:</b> Total portfolio value (availableBalance + positionMargin + orderMargin)</ListItem>
                                                                <ListItem><b>unrealisedPNL:</b> Profit/loss since purchase (based on mark price, not index price or last price)</ListItem>
                                                                <ListItem><b>positionMargin:</b> Value of open positions (initialMargin + realisedPNL + unrealisedPNL). Realised PNL includes trading fees, funding fees, and partially closed positions</ListItem>
                                                                <ListItem><b>availableBalance:</b> Unpsent amount</ListItem>
                                                                <ListItem><b>marginBalance:</b> accountEquity - unrealisedPNL</ListItem>
                                                                <ListItem><b>orderMargin:</b> Committed to open orders (actual amount, pre-leverage)</ListItem>
                                                                <ListItem><b>frozenFunds:</b> TBC</ListItem>
                                                            </UnorderedList>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2'  size={'lg'}>Account overview (USDT)</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            {KucoinHoldings()}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2'  size={'lg'}>KuCoin positions</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            {kucoinPositions()}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2'  size={'lg'}>KuCoin open orders</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            {kucoinOpenOrders()}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2'  size={'lg'}>KuCoin funding rate trade profitability</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            {kucoinFundingRateTradeDetails()}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        <Card size={'sm'}>
                            <CardBody>
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Heading as='h2'  size={'lg'}>KuCoin futures</Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel>
                                            {kucoinSymbolDetails()}
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </CardBody>
                        </Card>


                        {/*<Card size={'sm'}>*/}
                        {/*    <CardBody>*/}
                        {/*        <Accordion allowToggle>*/}
                        {/*            <AccordionItem>*/}
                        {/*                <AccordionButton>*/}
                        {/*                    <Box as="span" flex='1' textAlign='left'>*/}
                        {/*                        <Heading as='h2'  size={'lg'}>Funding rate</Heading>*/}
                        {/*                    </Box>*/}
                        {/*                    <AccordionIcon />*/}
                        {/*                </AccordionButton>*/}
                        {/*                <AccordionPanel>*/}
                        {/*                    {chartTwo()}*/}
                        {/*                </AccordionPanel>*/}
                        {/*            </AccordionItem>*/}
                        {/*        </Accordion>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}

                        {/*<Card size={'sm'}>*/}
                        {/*    <CardBody>*/}
                        {/*        <Accordion allowToggle>*/}
                        {/*            <AccordionItem>*/}
                        {/*                <AccordionButton>*/}
                        {/*                    <Box as="span" flex='1' textAlign='left'>*/}
                        {/*                        <Heading as='h2'  size={'lg'}>Price</Heading>*/}
                        {/*                    </Box>*/}
                        {/*                    <AccordionIcon />*/}
                        {/*                </AccordionButton>*/}
                        {/*                <AccordionPanel>*/}
                        {/*                    {chartOne()}*/}
                        {/*                </AccordionPanel>*/}
                        {/*            </AccordionItem>*/}
                        {/*        </Accordion>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}

                    </Stack>
                </Container>
            </Flex>

            <Footer statusDetails={{
                numConnectedClients: numConnectedClients,
                lastUpdatedNumClients: lastUpdatedNumClients}} />
        </div>
    );
}

export default Trade;