import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {Heading, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Button, Box, Text, useColorModeValue} from "@chakra-ui/react";



function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const storedAccessToken = Cookies.get('accessToken');
        if (storedAccessToken) {
            // Handle successful login based on the stored token
            // Redirect to the appropriate page or perform other actions
            console.log('User already logged in with accessToken:', storedAccessToken);
            setError('User already logged in.');
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Replace with actual authentication logic
        if (username === 'admin' && password === 'password') {
            const accessToken = 'your_generated_access_token';
            Cookies.set('accessToken', accessToken, { expires: 14 }); // Set expiration for 14 days
            console.log('Login successful. Access token:', accessToken);
            setError('Login successful.');
            window.location.href = '/';
        } else {
            setError('Invalid username or password');
        }
    };

    const bg = useColorModeValue('white', 'gray.800')

    return (
        // <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box  bg={bg} sx={{
            padding: "20px", // Add some padding for better spacing
            borderRadius: "10px", // Optional rounded corners
            maxWidth: "400px", // Adjust this value to your desired maximum width
            margin: "0 auto", // Center the box horizontally
        }}>
            <form onSubmit={handleSubmit}>
                <FormControl>
                    <Heading variant="gradient" as='h1'>Log In</Heading>  {/* TODO: Center this*/}

                    {/*<FormLabel>Username</FormLabel>*/}
                    <Input type="text" id="username" placeholder='Username' variant={'outline'} sx={{ width: '100%', marginTop: '20px'}} value={username} onChange={(e) => setUsername(e.target.value)} required />

                    {/*<FormLabel>Password</FormLabel>*/}
                    <Input type="password" id="password" placeholder='Password' variant={'outline'} sx={{ width: '100%', marginTop: '20px'}} value={password} onChange={(e) => setPassword(e.target.value)} required />

                    <Button type="submit" size='md' variant={'solid'} colorScheme={'pink'} sx={{ width: '100%', marginTop: '20px'}} isDisabled={!(username && password)}>Log in</Button>

                    {error && <Text fontSize='lg' sx={{ width: '100%', marginTop: '10px'}}>{error}</Text>}

                </FormControl>
            </form>

        </Box>
    );
}

export default Login;