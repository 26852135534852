import React from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

// Function to get the access token from cookies
const getAccessToken = () => {
    return Cookies.get('accessToken');
}

// Function to check if the user is authenticated
const isAuthenticated = () => {
    return !!getAccessToken();
}

function ProtectedRoute({ children }) {
    const isLoggedIn = isAuthenticated();

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;