import {
    Box,
    chakra,
    Container,
    ButtonGroup,
    IconButton,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden, Heading, SimpleGrid, useStyleConfig
} from '@chakra-ui/react'
import { FaLinkedin, FaGithub, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'
import { Logo } from '../assets/Logo'
import React, {ReactNode} from "react";
import {StarIcon} from "@chakra-ui/icons";

const SocialButton = ({
                          children,
                          label,
                          href,
                      }: {
    children: ReactNode, label: string, href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

function allRightsReserved() {
    return (
        <div>
            <Text fontSize="sm" color="fg.subtle">
                Copyright &copy; {new Date().getFullYear()} Kumaran Nathan Limited. All rights reserved.
            </Text>
            {/*<Text fontSize="sm" color="fg.subtle">*/}
            {/*    kumarannathan is a trading name of Kumaran Nathan Limited. Company number 03479577.*/}
            {/*</Text>*/}
            {/*<Text fontSize="sm" color="fg.subtle">*/}
            {/*    Kumaran Nathan Limited is registered and incorporated in England and Wales at 124 City Road, London EC1V 2NX.*/}
            {/*</Text>*/}
        </div>
    )
}

function socialLogos() {
    return (
        // <ButtonGroup variant="tertiary">
        //     <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin/>}/>
        //     <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub/>}/>
        //     <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter/>}/>
        //     <IconButton as="a" href="#" aria-label="YouTube" icon={<FaYoutube/>}/>
        //     <IconButton as="a" href="#" aria-label="Instagram" icon={<FaInstagram/>}/>
        // </ButtonGroup>
        <Stack direction={'row'} spacing={6}>
            <SocialButton label={'LinkedIn'} href={'#'}>
                <FaLinkedin />
            </SocialButton>
            <SocialButton label={'GitHub'} href={'#'}>
                <FaGithub />
            </SocialButton>
            <SocialButton label={'Twitter'} href={'#'}>
                <FaTwitter />
            </SocialButton>
            <SocialButton label={'YouTube'} href={'#'}>
                <FaYoutube />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'#'}>
                <FaInstagram />
            </SocialButton>
        </Stack>
    )
}

export default function Footer({ statusDetails }) {
    const styles = useStyleConfig("Footer")

    return (
        <Box as="footer" role="contentinfo"
            // bg="bg.accent.default"
            //  bg={useColorModeValue('gray.50', 'gray.900')}
            //  bg={useColorModeValue('gray.50', 'gray.900')}
             __css={styles}
             color={useColorModeValue('gray.700', 'gray.200')}
        >

            {/*<Container maxW={'container.xl'} py={2} />*/}
            {/*<Container*/}
            {/*    as={Stack}*/}
            {/*    maxW={'container.xl'}*/}
            {/*    spacing={4}*/}
            {/*    direction={{ base: 'column', md: 'row' }}*/}
            {/*    justify={{ base: 'center', md: 'space-between' }}*/}
            {/*    align={{ base: 'center', md: 'center' }}>*/}
            {/*    >*/}

            {/*    <Logo />*/}
            {/*    /!*<Stack direction='row' align={{ base: 'center', md: 'center' }}>*!/*/}
            {/*    /!*    <StarIcon boxSize={8}/>*!/*/}
            {/*    /!*    <Heading>KumaranNathan</Heading>*!/*/}
            {/*    /!*</Stack>*!/*/}

            {/*    {socialLogos()}*/}
            {/*</Container>*/}

            <Container
                as={Stack}
                maxW={'container.xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                >
                    <Text fontSize="sm" color="fg.subtle">
                        Number of clients connected (KuCoin): {statusDetails.numConnectedClients} (as of {statusDetails.lastUpdatedNumClients})
                    </Text>
            </Container>

            <Container
                as={Stack}
                maxW={'container.xl'}
                direction={{ base: 'column', md: 'row' }}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                >
                {allRightsReserved()}
            </Container>

            <Container maxW={'container.xl'} py={2} />

        </Box>
    )
}