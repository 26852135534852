// Run with 'npm start'

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'

// import { supabase } from './utils/supabase.ts'

import theme from "./theme/theme"

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Trade from "./pages/Trade"
import PageNotFound from "./pages/404Page"

function App() {
    return (
        <ChakraProvider theme={theme}>
            {/*<Box bgGradient='linear(red.100 0%, orange.100 25%, yellow.100 50%)'>*/}
            {/*<Box*/}
            {/*    w='100%'*/}
            {/*    // h='100vh'*/}
            {/*    h='100%'*/}
            {/*    bgGradient={[*/}
            {/*        'linear(to-tr, teal.300, yellow.400)',*/}
            {/*        'linear(to-t, blue.200, teal.500)',*/}
            {/*        'linear(to-b, orange.100, purple.300)',*/}
            {/*    ]}*/}
            {/*>*/}
                <Router>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/" element={<ProtectedRoute><Trade /></ProtectedRoute>} />
                        <Route path="/trade" element={<Trade />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Router>
            {/*</Box>*/}
        </ChakraProvider>
    );
}

export default App;