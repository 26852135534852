'use client'

import React from "react";
import {
    Box,
    Flex,
    Avatar,
    Container,
    Button,
    useStyleConfig,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    useColorMode,
    Link,
    Center, SimpleGrid, Heading,
} from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { LockIcon, StarIcon } from '@chakra-ui/icons'
import { Logo } from '../assets/Logo'
import { useNavigate } from 'react-router-dom'; // For redirecting after logout
import Cookies from 'js-cookie';

// Function to get the access token from cookies
const getAccessToken = () => {
    return Cookies.get('accessToken');
}

// Function to check if the user is authenticated
const isAuthenticated = () => {
    return !!getAccessToken();
}

// interface Props {
//     children: React.ReactNode
// }
//
// const NavLink = (props: Props) => {
//     const { children } = props
//
//     return (
//         <Box
//             as="a"
//             px={2}
//             py={1}
//             rounded={'md'}
//             _hover={{
//                 textDecoration: 'none',
//                 bg: useColorModeValue('gray.200', 'gray.700'),
//             }}
//             href={'#'}>
//             {children}
//         </Box>
//     )
// }

export default function Nav() {
    const isLoggedIn = isAuthenticated();
    const navigate = useNavigate();

    const styles = useStyleConfig("NavBar")

    const { colorMode, toggleColorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleLogout = () => {
        // Remove the 'accessToken' cookie
        Cookies.remove('accessToken');

        // Redirect to the login page or home page after logout
        navigate('/login');
    };

    return (
        <>
            <Box
                as="nav"
                role="navigation"
                // bg="bg.accent.default"
                // bg={useColorModeValue('gray.100', 'gray.900')}
                __css={styles}
                px={4}
            >
                <Container maxW={'container.xl'}>
                    <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

                        <Link href="/">
                            <Logo />
                        </Link>
                        {/*<Stack direction='row' align={{ base: 'center', md: 'center' }}>*/}
                        {/*    <StarIcon boxSize={8}/>*/}
                            {/*<Heading>KumaranNathan</Heading>*/}
                        {/*</Stack>*/}


                        <Flex alignItems={'center'}>
                            <Stack direction={'row'} spacing={7}>
                                <Button onClick={toggleColorMode}>
                                    {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                                </Button>

                                {isLoggedIn && <Button onClick={handleLogout}>Log out</Button>}

                                {/*<Menu>*/}
                                {/*    <MenuButton*/}
                                {/*        as={Button}*/}
                                {/*        rounded={'full'}*/}
                                {/*        variant={'link'}*/}
                                {/*        cursor={'pointer'}*/}
                                {/*        minW={0}>*/}
                                {/*        <Avatar*/}
                                {/*            size={'sm'}*/}
                                {/*            src={'https://avatars.dicebear.com/api/male/username.svg'}*/}
                                {/*        />*/}
                                {/*    </MenuButton>*/}
                                {/*    <MenuList alignItems={'center'}>*/}
                                {/*        <br />*/}
                                {/*        <Center>*/}
                                {/*            <Avatar*/}
                                {/*                size={'2xl'}*/}
                                {/*                src={'https://avatars.dicebear.com/api/male/username.svg'}*/}
                                {/*            />*/}
                                {/*        </Center>*/}
                                {/*        <br />*/}
                                {/*        <Center>*/}
                                {/*            <p>Username</p>*/}
                                {/*        </Center>*/}
                                {/*        <br />*/}
                                {/*        <MenuDivider />*/}
                                {/*        <MenuItem>Your Servers</MenuItem>*/}
                                {/*        <MenuItem>Account Settings</MenuItem>*/}
                                {/*        <MenuItem>Logout</MenuItem>*/}
                                {/*    </MenuList>*/}
                                {/*</Menu>*/}
                            </Stack>
                        </Flex>
                    </Flex>
                </Container>
            </Box>
        </>
    )
}