import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // For redirecting after logout
import {Heading, Button, Box} from "@chakra-ui/react";

function Logout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove the 'accessToken' cookie
        Cookies.remove('accessToken');

        // Redirect to the login page or home page after logout
        navigate('/login');
    };

    return (
        <Box>
            <Heading variant="gradient" as='h1'>Log Out</Heading>

            <Button size='md' onClick={handleLogout}>Log out</Button>
        </Box>
    );
}

export default Logout;