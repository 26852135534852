'use client'

import {
    Box,
    chakra,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
} from '@chakra-ui/react'

interface StatsCardProps {
    title: string,
    stat: string
}
function StatsCard(props: StatsCardProps) {
    const { title, stat } = props
    return (
        <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            // shadow={'md'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <StatLabel /*fontWeight={'medium'} isTruncated*/>
                {title}
            </StatLabel>
            <StatNumber /*fontSize={'2xl'} fontWeight={'medium'}*/>
                {stat}
            </StatNumber>
        </Stat>
    )
}

export default function BasicStatistics({ accountOverview }) {
    return (
        <Box /*maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}*/>
            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
                <StatsCard title={'accountEquity'} stat={accountOverview.accountEquity} />
                <StatsCard title={'unrealisedPNL'} stat={accountOverview.unrealisedPNL} />
                <StatsCard title={'positionMargin'} stat={accountOverview.positionMargin} />
                <StatsCard title={'availableBalance'} stat={accountOverview.availableBalance} />
                <StatsCard title={'marginBalance'} stat={accountOverview.marginBalance} />
                <StatsCard title={'orderMargin'} stat={accountOverview.orderMargin} />
                <StatsCard title={'frozenFunds'} stat={accountOverview.frozenFunds} />
            </SimpleGrid>
        </Box>
    )
}