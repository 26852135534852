import React from "react";

import { Box, Container, Flex } from '@chakra-ui/react'

import Nav from "../components/Nav";
import Login from "../components/Login";


export default function LoginPage() {
    return (
        <Box>
            <Nav />

            <Flex as="main" role="main" direction="column" flex="1" py="4">
                <Container maxW='container.xl'>

                    <Login />

                </Container>
            </Flex>
        </Box>
    );
}
